import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import profile from "./logo.png";
import Maincontent from "./Maincontent";

export const Hero = () => {
  const [transform, setTransform] = useState({ scale: 1, opacity: 1 });
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

 
  const handleScroll = () => {
    const newScale = 1 + window.scrollY / 500;
    const newOpacity = 1 - window.scrollY / 800;

    setTransform({
      scale:  newScale > 3 ? 3 : newScale,
      opacity: newOpacity < 0 ? 0 : newOpacity,
    });
  };



  return (
    <div >
     
  
    <Box
      sx={{
        height: "100vh",
        
        opacity: `${transform.opacity}`,
        color: "white",
        alignItems: "flex-end",
       width:"100%",
        m: "0rem",
       
        position: "relative",
      }}
    >  
    <Box
    sx={{
      position: "fixed",
      left: 0,
      right: 0,
      bottom:0,

      top:0,
      opacity: `${transform.opacity}`,
      display:"flex",
      height: "100vh",
      color: "white",
      mx: "auto",
    
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    <Maincontent></Maincontent>
   
    <img

      alt="profilepic"
      style={{
        objectFit: "cover",
        transformOrigin: "bottom",
        transition: "all 50ms ease",
        transform: `scale(${transform.scale})`,
        height: "100vh",
        width: "100%",
      }}
      src={profile}
    ></img>
  </Box>
  
   
      
    
     
    </Box>
    </div>
  );
};




