import { Box, Typography } from "@mui/material";

import React from "react";

const About = () => {

  return (
    <div >
    <Box sx={{ bgcolor: "#273433", color: "white", pt: "50rem" }}>
      <section id="about" >
        <Box sx={{ px: "1rem" }}>
          <Typography
            sx={{
              lineHeight: "1.2",
              fontWeight: "800",
              fontSize: {
                lg: "4rem",
                xl: "4rem",
                xs: "2rem",
                s: "2rem",
                md: "4rem",
              },
            }}
          >
           Hi 👋, I am a final year student at NSUT, New Delhi. I am a
            Full Stack Web Developer. I love to write clean code and work with
            Open Source tools & technologies. I have been a Google Summer of
            Code contributor in 2022. Do checkout my projects in the next section.
            <br></br>
            <br></br>
            I am currently looking for internships and full time roles.
           <br></br>
            My Tech Stack - React, Node, MongoDb, Firebase, C++, Redux, and Git.
          </Typography>
        </Box>
      </section>
    </Box>
    </div>
  );
};

export default About;
