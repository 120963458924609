import {  Box, Drawer, ThemeProvider, Typography, createTheme } from "@mui/material";
import  { Hero } from "./Hero";
import "./App.css";
import About from "./About";
import Projects from "./Projects";
import Contact from "./Contact";
import { useEffect, useState } from "react";

function App() {
  const [drawerOpen, setDrawerOpen] = useState(true);


 


  useEffect(() => {
    const timer = setTimeout(() => {
      setDrawerOpen(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);
  const theme = createTheme({
    typography: {
      fontFamily: "Syncopate, sans-serif",
    },
  });
  return (
    <ThemeProvider theme={theme}>

    <Drawer
        anchor="top"
        sx={{height:"100%",pointerEvents:"all"}}
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
      >
         <Box sx={{height:"100vh",bgcolor:"black"}}>
        <Typography variant="h2" style={{color:"white", padding: '20px' }}>
          Loading ...
        </Typography>
        </Box>
      </Drawer>
    
      <Box className="App">
    
    <Hero></Hero> 
  
      
      
        <About></About>
        <Projects></Projects>
        <Contact></Contact>
      </Box>
    
  
    </ThemeProvider>

  );
}

export default App;
