import { Box, Typography } from "@mui/material";
import React from "react";

const Projects = () => {
  const projects = [
    {name:"Makaan",link:"https://makaandotcom.vercel.app/"}, 
    {name:"Chat.IO",link:"https://chatiobysahil.netlify.app/"}, 
    {name:"Sort Visualiser",link:"https://rookiecoder9.github.io/sort-visualizer/"}];
  return (
    <div data-scroll-container>
    <Box sx={{ background:"linear-gradient(0deg, rgba(0,0,0,1) 10%, rgba(39,52,51,1) 99%)", color: "white", py: "30rem" }}>
      <section id="projects">
        <Box sx={{ textAlign:"left",position:"relative",zIndex:10 }}>
          {projects.map((ele, ind) => {
            return (
                <Box sx={{p:"1rem",borderTop:"2px solid white"}}>
                                                <a href={ele.link} rel="noreferrer" target="_blank" style={{cursor:"inherit",textDecoration:"none",color:"white"}}>
              <Typography
                sx={{
                  lineHeight: "1.2",
                  fontSize: {
                    lg: "5rem",
                    xl: "5rem",
                    xs: "2rem",
                    s: "2rem",
                    md: "4rem",
                  },
                }}
              >
                {ele.name}
              </Typography>
              </a>
              </Box>
            );
          
          })}
          <Box sx={{borderTop:"2px solid white"}}></Box>
        </Box>
      </section>
    </Box>
    </div>
  );
};

export default Projects;
