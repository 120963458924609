import { Box, Typography } from '@mui/material';
import React from 'react'
import './App.css'

const Contact = () => {
    const contacts=[
        {name:"github",link:"https://github.com/rookieCoder9"},
   {name:"email",link:"mailto:nsut.sahilkumar@gmail.com"},
   {name: "instagram",link:"https://www.instagram.com/hifromsahil/"},
    {name:"linkedin",link:"https://www.linkedin.com/in/sahil-kumar-b58752221/"},
{name:"leetcode",link:"https://leetcode.com/sahildavcool/"}]
  return (
   
    <Box sx={{ background:"black", color: "black", pt: "10rem" ,pb:"5rem"}}>
    <section id='contact'>
      <Box sx={{ p: "1rem",textAlign:"center",position:"relative",zIndex:10 }}>
        {contacts.map((ele, ind) => {
          return (
              <Box sx={{p:"1rem"}}>
                            <a href={ele.link} target='_blank' rel='noreferrer' style={{textDecoration:"none",color:"white",cursor:"inherit"}}>
            <Typography
            id='links'
              sx={{
                '&:hover':{
                    color:"white"
                },
            color:"black",
            transition:"color 0.3s ease-in",
              
                textShadow:
                '-1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff',
                lineHeight: "1.2",
                fontSize: {
                  lg: "5rem",
                  xl: "5rem",
                  xs: "2rem",
                  s: "2rem",
                  md: "4rem",
                },
              }}
            >
              {ele.name}
            </Typography>
            </a>
            </Box>
          );
        
        })}
    
      </Box>
    </section>
  </Box>
  
  )
}

export default Contact