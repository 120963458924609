import { Box, Typography } from "@mui/material";
import React from "react";

const Maincontent = () => {
  const Links = [
    { name: "About", link: "#about" },
    { name: "Projects", link: "#projects" },
    { name: "Contact", link: "#contact" },
  ];
  const Contacts = [
    { name: "github", link: "https://github.com/rookieCoder9" },
    {
      name: "linkedin",
      link: "https://www.linkedin.com/in/sahil-kumar-b58752221/",
    },
    { name: "instagram", link: "https://www.instagram.com/hifromsahil/" },
  ];
  return (
    <Box
      sx={{
        position: "absolute",
        left: 0,
        right: 0,
        top:0,
        height: "98vh",
        color: "white",
        zIndex: "2",
      
      }}
    >
      <Box sx={{ position: "absolute", left: 0, top: 0, m: "1rem" }}>
        <Typography
          sx={{
            fontSize: "1.5rem",
            lineHeight: "0.9",
            fontWeight: "800",
          }}
        >
          {" "}
          Sahil <br></br> Kumar
        </Typography>
      </Box>
      <Box
        sx={{
          position: "absolute",
          textAlign: "right",
          top: 0,
          right: 0,
          m: "1rem",
        }}
      >
        {Links.map((ele, ind) => {
          return (
            <a
              href={ele.link}
              style={{
                cursor: "inherit",
                textDecoration: "none",
                color: "white",
              }}
            >
              <Typography
                key={ind}
                sx={{
                  "&:hover": {
                    scale: "1.1",
                  },
                  transition: "all 0.2s ease-in",
                  lineHeight: "1.1",
                  fontSize: "1.3rem",
                  fontWeight: "800",
                }}
              >
                {" "}
                {ele.name}
              </Typography>
            </a>
          );
        })}
      </Box>
      <Box
        sx={{
          position: "absolute",
          textAlign: "center",
          bottom: 0,
          mx: "auto",
          left: 0,
          right: 0,
          mb: {
            xl: "10rem",
            lg: "10rem",
            xs: "12rem",
            s: "12rem",
            md: "15rem",
          },
        }}
      >
        <Typography
          sx={{
            zIndex: "6",
            cursor: "inherit",

            lineHeight: "0.9",

            fontSize: {
              xl: "4rem",
              lg: "4rem",
              xs: "2.5rem",
              s: "2rem",
              md: "4rem",
            },
          }}
          fontWeight={800}
        >
          {" "}
          Full Stack <br></br>Developer
        </Typography>
      </Box>
      <Box sx={{ position: "absolute", bottom: 0, left: 0, m: "1rem" }}>
        {Contacts.map((ele, ind) => {
          return (
            <a
              href={ele.link}
              rel="noreferrer"
              target="_blank"
              style={{
                cursor: "inherit",
                textDecoration: "none",
                color: "white",
              }}
            >
              <Typography
                key={ind}
                sx={{
                  "&:hover": {
                    scale: "1.1",
                  },
                  transition: "all 0.2s ease-in",
                  lineHeight: "1.2",
                  fontSize: "1.5rem",
                  fontWeight: "800",
                }}
              >
                {" "}
                {ele.name}
              </Typography>
            </a>
          );
        })}
      </Box>
    </Box>
  );
};

export default Maincontent;
